.dotPantientRegisterWaiting{
    display: block;
    margin: auto;
    height: 15px;
    width: 15px;
    background-color: #d32f2f;
    border-radius: 50%;
}

.dotPantientRegisterExamining{
    display: block;
    margin: auto;
    height: 15px;
    width: 15px;
    background-color: #f9a825;
    border-radius: 50%;
}

.dotPantientRegisterDone{
    display: block;
    margin: auto;
    height: 15px;
    width: 15px;
    background-color: #388e3c;
    border-radius: 50%;
}

.dotPantientRegisterCancel{
    display: block;
    margin: auto;
    height: 15px;
    width: 15px;
    background-color: #d32f2f;
    border-radius: 50%;
}