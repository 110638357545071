.category-scrollbar {
    overflow: auto;
}

.category-scrollbar::-webkit-scrollbar{
    width: 6px;
}

.category-scrollbar::-webkit-scrollbar-track{
    // border-radius: 20px;
    // background: black;
    margin: 2px;
    //cả cây scroll bar
}

.category-scrollbar::-webkit-scrollbar-thumb{
    background-color: darkgrey;
    border-radius: 4px;
    //cây scroll bar đang nằm tại vị trí
}

.category-scrollbar::-webkit-scrollbar-thumb:hover{
    background: #555; 
}

.container-content-examining{
    width: 100%;
    height: 540px;
    //box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12);
    border-radius: 20px;
    border: 2px solid #ff8a65;
    overflow-y: scroll;
    position: relative;

    .content-examining{
        padding: 0px 12px 0px 12px;
        height: 535px;
        overflow: auto;
    }

    //thanh cuộn cho div content-examining
    .content-examining::-webkit-scrollbar{
        width: 6px;
        height: 6px;
    }

    .content-examining::-webkit-scrollbar-track{
        background-color: silver;
        border-radius: 4px;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .content-examining::-webkit-scrollbar-thumb{
        width: 4px;
        background-color: #555;
        border-radius: 4px;
    }

    // .content-examining::-webkit-scrollbar-thumb:hover{
    //     background: #555; 
    // }

    .textfield-for-answer{
        border: 2px solid rgb(218,220,224);
        border-top: 0px;
        padding: 0.5px;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;

        .suggest-note{
            width: 100%;
            outline: none;
            display: flex;
            align-items: center;

            .textarea-autosize{
                width: 100%;
                color: red;
                border: none;
                outline: none;
            }
        }

        .value-for-answer{
            width: 100%;
            color: red;
            border: none;
            outline: none;
            font-size: 1.14rem;
        }
    }

    .chipUnDoneExamms {
        border-radius: 20px;
        padding: 6px;
        color: #fff;
        background-color: #ff1744;
    }
    
    .chipDoingExamms {
        border-radius: 20px;
        padding: 6px;
        color: #fff;
        background-color: #ff9100;
    }
    
    .chipDoneExamms {
        border-radius: 20px;
        padding: 6px;
        color: #fff;
        background-color: #00c853;
        text-align: center;
    }
    
    .chipLabel {
        margin: 0;
    }

    .footer-content-examining{
        position: absolute;
        bottom: 0;
        display: flex;
        justify-content: center;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
        background-color: #ff8a65;
        height: 4.8vh;
        width: 100%;
    }
}

.container-content-examining::-webkit-scrollbar{
    display: none;
}

.box-webkit-scrollbar::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  
.box-webkit-scrollbar::-webkit-scrollbar-thumb {
// background-color: gray;
// border-radius: 10px; /* Bo góc cho thanh trượt */
width: 4px;
background-color: #555;
border-radius: 4px;
}

.box-webkit-scrollbar::-webkit-scrollbar-track {
// background-color: transparent;
margin: 10px 5px 10px 5px;
}