.note-for-answer{
    border: 2px solid rgb(218,220,224);
    border-top: 0px;
    padding: 0.5px;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;

    .suggest-note{
        width: 100%;
        outline: none;
        display: flex;
        align-items: center;

        .textarea-autosize{
            width: 100%;
            color: red;
            border: none;
            outline: none;
        }
    }
}